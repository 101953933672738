/* nav open/close
*********************************************/
export function nav() {
const navBtn = document.querySelector('.nav__btn'),
			nav = document.querySelector('.nav__sp');

navBtn.addEventListener('click', () => {
	navBtn.classList.toggle('is-opened');
	nav.classList.toggle('is-opened');
	document.body.classList.toggle('is-opened');
}, false);
}
