/* tab
*********************************************/
export function tab() {
  $('.top-news__btn').on('click', function(){
		let index = $('.top-news__btn').index(this);
    $('.top-news__btn, .top-news__items').removeClass('is-current');
		$(this).addClass('is-current');
		$('.top-news__items').eq(index).addClass('is-current');
  });
}
