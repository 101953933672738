/* swiper
*********************************************/
export function swiper() {
	let mvSlide = new Swiper('.mv', {
		autoplay: {
			delay: 4000,
			disableOnInteraction: true
		},
	  loop: true,
		speed: 1000,
	  autoHeight: false,
		resizeReInit: true,
		effect: 'fade',
	});
}
