/* 指定したアンカー(#ID)へアニメーションでスクロール
*********************************************/
export function smoothScroll() {
	function scrollToAnker(hash) {
		let target = $(hash),
				pcPosition = target.offset().top,
				spPosition = pcPosition - 60;

		if (matchMedia('(max-width: 1279px)').matches) {
			$('body,html').stop().animate({scrollTop:spPosition}, 500);
		} else {
			$('body,html').stop().animate({scrollTop:pcPosition}, 500);
		}
	}
	//URLのハッシュ値を取得
	const urlHash = location.hash;
	//ハッシュ値があればページ内スクロール
	if(urlHash) {
		//スクロールを0に戻しておく
		$('body,html').hide();
		setTimeout(function () {
			$('body,html').show();
			//ロード時の処理を待ち、時間差でスクロール実行
			scrollToAnker(urlHash) ;
		}, 100);
	}

	//通常のクリック時
	$('a[href^="#"]').on('click', function() {
		let href= $(this).attr("href"),//ページ内リンク先を取得
				hash = href == "#" || href == "" ? 'html' : href;	//リンク先が#か空だったらhtmlに
		//スクロール実行
		scrollToAnker(hash);
		return false;
	});
}
