/* nav open/close
*********************************************/
export function navChild() {
if (matchMedia('(min-width: 1280px)').matches) {
	$(".nav__parent").hover(function(){
		$(this).addClass('is-active').next('.nav-child').addClass('is-active');
	},function(){
		$(this).removeClass('is-active').next('.nav-child').removeClass('is-active');
	});

	$(".nav-child").hover(function(){
		$(this).addClass('is-active').prev('.nav__parent').addClass('is-active');
	},function(){
		$(this).removeClass('is-active').prev('.nav__parent').removeClass('is-active');
	});
} else {
  $(".nav__parent").on('click', function(){
		$(this).toggleClass('is-active').next('.nav-child').toggleClass('is-active');
	});
}

}
