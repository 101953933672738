//import '@babel/polyfill';

//import {common} from "../modules/common";
import {changeImg} from "../modules/changeImg";
import {smoothScroll} from "../modules/smoothScroll";
//import {scrollAnimation} from "../modules/scrollAnimation";
import {nav} from "../modules/nav";
import {navChild} from "../modules/navChild";
import {swiper} from "../modules/swiper";
import {tab} from "../modules/tab";
import {hoverImg} from "../modules/hoverImg";

//common();
changeImg();
smoothScroll();
//scrollAnimation();
nav();
navChild();
swiper();
tab();
hoverImg();
